@import "../scss/variables.scss";
@import "../scss/transitions.scss";

.filter-view {
  .button--close>svg {
    stroke: white;
  }

  .filter--list {
    margin: 25px;
    background: #f1f1f1;
    color: #444;
    border-radius: 15px;
    padding: 15px;

    &:first-child {
      margin-top: 5px;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 15px;
      text-align: center;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        margin-top: 15px;
        display: flex;
        align-items: center;
      }
    }

    span {
      margin-left: auto;
      text-align: center;
      background: #444;
      border-radius: 7px;
      color: white;
      font-size: 0.7em;
      padding: 5px;
      min-width: 25px;
    }
  }

  h4 {
    font-size: 24px;
  }

  &--ggr {
    background: $ggrColor;
  }

  &--ggr {
    background: $ggrColor;
  }

  &--srm {
    background: $srmColor;
    color: $miscColor;

    .button--close {
      color: $miscColor;
    }

    .button--close>svg {
      stroke: $miscColor;
    }
  }

  &--wm {
    background: $wmColor;
  }

  &--other {
    background: $otherColor;
  }

  &--search {
    background: white;
    color: $miscColor;

    .button--close {
      color: $miscColor;
    }

    .button--close>svg {
      stroke: $miscColor;
    }
  }

  .datefilter {
    padding-bottom: 80px;
    .datefilter__headline {
      margin-bottom: 50px !important;
    }

    .MuiSlider-root {
      display: block;
      margin: 0 auto;
      width: 85%;
      color: black;
      padding: 0 !important;

      * {
        padding: 0 !important;
      }

      .MuiSlider-markLabel {
        color: black;
        top: 15px;
        max-width: 40px;
        padding: 2px 6px !important;
        font-size: 12px;
        white-space: break-spaces;
        text-align: left;
      }

      .MuiSlider-thumb {
        border-radius: 50%;
        padding: 0 !important;
      }

      .MuiSlider-markLabel[data-index="0"] {
        left: 0% !important;
      }

      .MuiSlider-valueLabel {
        font-size: 30px !important;
        padding: 0px 5px 3px 5px !important;
        line-height: 1;
      }
      .MuiSlider-track {
        padding: 2px !important;
      }
    }
  }
}

.filter-type {
  margin-right: -$filterWidth;

  &.filter-type-enter {
    margin-right: 0;
  }

  &.filter-type-enter-done {
    opacity: 0.9;
    margin-right: 0;
    transition: $transition_fast;
  }

  &.filter-type-enter+&.filter-type-exit {
    position: fixed;
    right: 0;
    margin-right: 0;
    transition: $transition_fast;
  }

  &.filter-type-enter+&.filter-type-exit-active {
    opacity: 0;
  }

}

@media (max-width: 1000px) {

  .filter-view {
    height: calc(80vh - 50px);
    height: calc(80svh - 50px);
    max-width: 500px;
    width: calc(100vw - 10px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 15px;
    margin: 5px;
    border: 5px solid transparent;
    box-sizing: border-box;
    border-bottom: 15px solid transparent;
    padding: 5px;


    .controls--hidden & {
      height: calc(95vh - 50px);
      height: calc(95svh - 50px);
    }

  }

}

