@import "./variables";
@import "./transitions";
@import "./defaults";

body {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
}

*:focus {
  outline: none;
}

.button--close {
  padding: 3px;
  color: black;
  border: 2px solid;
  border-radius: 5px;
  line-height: 0;
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  .mapboxgl-control-container .description--active {
    //left: 510px;
    transition: $transition_fast;
  }

  .mapboxgl-canvas:focus {
    outline: none;
  }
}

.button--close {
  cursor: pointer;
  background: transparent;
  box-shadow: none;
  font-size: 25px;

  &:focus {
    outline: none;
  }
}

.welcome-window--container {
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100svh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .button--close {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  .welcome-window {
    position: relative;
    margin: 15px auto;
    padding: 25px;
    width: 100%;
    max-width: 50%;
    background: white;
    z-index: 10;
    border-radius: 15px;
    font-size: 18px;
    @media (max-width: 1000px) {
      & {
        position: absolute;
        margin: auto;
        width: 90%;
        max-width: 100%;
        max-height: 90%;
        max-height: 90svh;
        overflow: scroll;
        box-sizing: border-box;
        border: 2px solid #444;
      }
    }



    h3 {
      margin-top: 0;
    }
    a,
    a:visited,
    a:hover {
      color: #1a8450;
    }

    .consent-choices {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}

input[type="checkbox"] {
  visibility: hidden;
  display: none;
}
input[type="checkbox"] + label {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  font-family: sans-serif;
  line-height: 1.1;
  padding-left: 40px;
  margin-right: 5px;
}

input[type="checkbox"] + label:before {
  position: absolute;
  left: 0;
  width: 30px;
  height: 15px;
  border-radius: 30px;
  border: 2px solid #ddd;
  background-color: #eee;
  content: "";
  margin-right: 15px;
  transition: background-color 0.5s linear;
}

input[type="checkbox"] + label:after {
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background-color: #fff;
  content: "";
  transition: margin 0.1s linear;
  box-shadow: 0px 0px 5px #aaa;
  position: absolute;
  left: 2px;
  top: 2px;
}

input[type="checkbox"]:checked + label:before {
  background-color: #2b8718;
}

input[type="checkbox"]:checked + label:after {
  margin: 0 0 0 15px;
}

.loader {
  color: $miscColor;
  position: absolute;
  right: 50px;
  top: 10px;

  &.hide {
    opacity: 0;
    transition: $transition_fast;
  }

  &.show {
    opacity: 1;
  }
}

.initialloader-container {
  width: 100%;
  height: 100vh;
  height: 100svh;
  position: absolute;
  background: $miscColor;
  opacity: 0.8;
  display: flex;
  z-index: 9;

  .initialloader {
    color: white;
    margin: auto;
    font-size: 100px;
  }
}

.logo-container {
  position: absolute;
  bottom: 10px;
  left: 100px;
  z-index: 2;
  max-width: 100px;
  height: 35px;
  display: flex;
  align-items: flex-end;

  .logo-image {
    width: auto;
    height: 100%;
    margin-left: 10px;
    padding: 5px;
    border-radius: 7px;
    border: 2px solid rgba(68, 68, 68, 0.75);

    @media (max-width: 1000px) {
      & {
        max-height: 25px;
      }
    }

    &.logo-geoengineering {
      background: rgba(255, 255, 255, 0.75);
    }

    &.logo-hbs {
      background: rgba(68, 68, 68, 0.75);
    }

    img {
      height: 100%;
      width: auto;
    }
  }
}
