@import "../scss/variables.scss";
@import "../scss/transitions.scss";

.filter-search {
  background: white;

  .button--close {
    color: $miscColor;

    & > svg {
      stroke: $miscColor !important;
    }
  }

  .search-input {
    margin: 15px 0;
    display: flex;
    flex-direction: column;

    .search-form {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;

      label {
        margin-bottom: 10px;
      }

      input {
        width: auto;
        text-align: center;
        color: $miscColor;
        font-size: 20px;
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 2px solid $miscColor;
        font-weight: 800;
        padding: 5px 15px;

        &:focus {
          outline: none;
          color: $miscColor;
        }
      }
    }

    .search-clear {
      background: #444444;
      color: white;
      border-radius: 15px;
      font-weight: bold;
      padding: 5px;
      margin: 20px 20px 10px auto;
      width: 120px;
    }
  }

  .search-results {
    max-height: 100vh;
    max-height: 100svh;
    margin: 0 10px;
    overflow-y: scroll;

    .search-results--number {
      color: $miscColor;
      text-align: right;
      padding: 0 15px;
      font-weight: 700;
    }

    .search-results--container {
      cursor: pointer;
      padding: 5px 15px;
      margin: 15px 0;
      margin-right: 5px;
      color: white;
      background: #e2e2e2;
      border-radius: 15px;

      &.ggr {
        background: $ggrColor;
      }

      &.srm {
        background: $srmColor;
      }

      &.wm {
        background: $wmColor;
      }

      &.other {
        background: $otherColor;
      }

      &.undefined {
        background: #e2e2e2;
        color: black;
        margin: 10px 0 5px;
        cursor: auto;
      }

      .search-results--title {
        text-align: center;
      }

      .search-results--description {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
}
