.filter-view {
  width: 30vw;
  max-width: 500px;
  height: 100vh;
  height: 100svh;
  background: black;
  color: white;
  font-size: 18px;
  overflow-y: scroll;
  transition: $transition_fast;

  .button--close {
    color: $miscColor;

    & > svg {
      stroke: $miscColor;
    }
  }

  .fa-share-alt {
    width: 25px;
    height: 25px;
    padding: 5px;
    border: 2px solid white;
    border-radius: 15px;
  }
}
