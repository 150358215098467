.move-up {
  &-appear {
    position: relative;
    top: 50px;
    transition: $transition_fast;
  }

  &-appear-done {
    position: relative;
    top: 0;
    transition: $transition_fast;
  }
}

.fade-in {
  &-appear {
    opacity: 0 !important;
    transition: $transition_slow;

    &.move-up {
      position: relative !important;
      top: 50px !important;
      transition: $transition_slow;
    }
  }

  &-appear-done {
    opacity: 1;
    transition: $transition_slow;

    &.move-up {
      position: relative;
      top: 0;
      transition: $transition_slow;
    }
  }
}
