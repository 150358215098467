@import "../scss/variables.scss";

$containerWidth: 500px;

.description--container {
  width: $containerWidth;
  height: 100vh;
  height: 100svh;
  z-index: 1;
  background: white;
  color: white;
  position: absolute;
  top: 0;
  overflow: scroll;
  word-wrap: break-word;

  &.from-left {
    &-appear {
      left: -$containerWidth;
    }

    &-appear-done {
      left: 0;
      transition: $transition_fast;
    }
  }

  .button--close {
    position: absolute;
    right: 0;
    margin-right: 15px;
    margin-top: 10px;
    border: 2px solid;
    color: white;

    & > svg {
      stroke: white;
    }
  }

  .shareLink--wrapper {
    position: absolute;
    right: 57px;
    top: 10px;

    .shareLink--button {
      width: auto;
      background: transparent;
      position: relative;
      margin: 0 !important;
      .fa-share-alt {
        color: white;
        width: 20px;
        height: 20px;
        padding: 5px;
        border: 2px solid white;
        border-radius: 5px;
      }
    }
  }

  .shareLink--link {
    position: absolute;
    width: auto;
    max-width: 350px;
    top: 60px;
    right: 15px;
    margin-left: 15px;
    background: white;
    border-radius: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    z-index: 1;

    p {
      background: transparent;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .shareLink--copy {

      display: flex;

      span {
        color: black;
        display: inline-block;
        margin: auto;
        margin-right: 15px;

        & + .copy-to-clipboard {
          margin-left: 0;
        }
      }

      .copy-to-clipboard {
        width: 20px;
        height: 20px;
        padding: 5px;
        border: 2px solid black;
        border-radius: 5px;
        margin: 15px;
        margin-left: auto;
        display: block;
        float: right;
        cursor: pointer;

      }
    }

  }


  &.ggr {
    background-color: $ggrColor;
  }

  &.wm {
    background-color: $wmColor;
  }

  &.srm {
    background-color: $srmColor;
    color: $ggrColor;
    .button--close {
      color: $ggrColor;
    }
    .button--close > svg {
      stroke: $ggrColor;
    }

    .shareLink--wrapper .shareLink--button .fa-share-alt {
      color: $ggrColor;
      border-color: $ggrColor;
    }
  }

  &.other {
    background-color: $otherColor;
  }

  h3 {
    font-weight: 800;
    text-align: center;
    font-size: 24px;
    margin-top: 65px;
    margin-bottom: 45px;
  }

  h4,
  p,
  a {
    background: #f1f1f1;
    color: black;
  }

  p > a,
  span > a {
    display: inline !important;
    background: transparent !important;
    padding: 0 !important;
  }

  .properties {
    margin: 0 25px;
    h4,
    p,
    .properties__sources-headline,
    .properties__sources-link {
      padding: 15px;
      border-radius: 15px;
      line-height: 1.4;
    }
    h4,
    .properties__sources-headline {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    p {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      & > p {
        padding: 0;
        margin-bottom: 7px;
      }
    }
    .properties__sources-link {
      display: inline-block;
      box-sizing: border-box;
      width: 100%;
      border-radius: 0;
      padding: 8px 15px;

      &:before {
        content: '';
        position: relative;
        float: left;
        top: 10px;
        display: inline-block;
        margin-right: 5px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #444;
      }

      &:last-of-type {
        margin-bottom: 10px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 15px;
        padding-top: 8px;
      }
    }
  }

  .type-description {
    padding: 25px;
    padding-bottom: 50px;
    background: #f1f1f1;
    color: black;
  }
}

@media (max-width: 1000px) {
  .description--container {
    width: 100%;
    height: 100vh;
    height: 100svh;
    z-index: 2;

    & ~ .mapContainer .mapboxgl-ctrl-top-left {
      display: none;
    }

  }
}
