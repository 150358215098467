.filter-view-about-map {
  background: white;
  color: black;

  .button--close {
    color: black;

    & > svg {
      stroke: black;
    }
  }

  a {
    color: black;
  }

  h4 {
    margin-top: 25px;
    margin-bottom: 0;
  }

  p {
    margin: 7px 0;
  }

  .header,
  .content {
    padding: 5px;
    padding-left: 15px;
  }
  .content {
    padding-bottom: 35px;
  }

  .licence {
    display: block;
    margin: 5px 0;
  }

  .MuiButtonBase-root {
    border: 5px solid #444;
    border-radius: 15px;
    background: #444;
    color: white;
    padding: 15px;
    font-weight: bold;
    margin-top: 15px;

    &:hover {
      color: #444;
    }
  }
}

.control-button--misc.control-button--about-map {
  margin-top: auto;
  order: 99;
  margin-bottom: 55px;
}

@media (max-width: 1000px) {
  .header {
    margin-top: 20px;
  }
}
