@import "../scss/variables.scss";

.controls-container {
  position: absolute;
  right: 0;
  width: auto;
  height: 100%;
  z-index: 1;
  display: flex;
  transition: $transition_fast;

  .datefilter {
        position: absolute;
        z-index: 1;
        width: 250px;
        right: calc(100% + 15px);
        top: 10px;
        padding: 0 40px;
        background: #444444b5;
        color: white;
        display: flex;
        border-radius: 15px;
        flex-direction: column;

        .button--close {
          display: none;
        }

        &__headline {
          text-align: center;
          margin-bottom: 40px;
        }

        .MuiSlider-root {
          color: inherit;

          .MuiSlider-markLabel {
            color: black;
            background: white;
            padding: 5px;
            border-radius: 5px;
            width: auto;
            text-align: center;

            &[data-index="0"] {
              left: 0% !important;
              font-size: 0.8em;
            }

            &[data-index="1"] {
              left: 100% !important;
              font-size: 0.8em;
            }

            &:before {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              background: linear-gradient(to bottom right, transparent 0%, transparent 50%, white 50%, white 100%);
              transform: rotate(225deg);
              position: absolute;
              top: -5px;
              left: calc(50% - 5px);
            }
          }
          .MuiSlider-valueLabel {
              color: black;
              background: white;
              font-size: 0.75em;
          }
        }
      }


  .button--close {
    margin-left: 15px;
    margin-top: 10px;
    color: white;
    border: 2px solid;
    border-radius: 5px;

    &.filter-close--mobile {
      display: none;
      @media (max-width: 1000px) {
        display: block;
        position: absolute;
        top: -20px;
        right: 5px;
        z-index: 9999;
        background: #444;
        margin: 0;
        border-radius: 50%;
        transition: all 0.25s linear;

        svg {
          stroke: white;
        }
      }
    }
  }

  &.controls--hidden {

    height: 65px;

    .button--close.filter-close--mobile  {
      transform: rotate(45deg);
    }

    .control-button {
      display: none;
    }
  }

  .control-buttons {
    background: white;
    overflow-y: auto;
    transition: $transition_fast;
    display: flex;
    flex-direction: column;

    &-enter-done {
      transition: $transition_fast;
    }
  }

  .control-button {
    position: relative;
    cursor: pointer;
    width: 100px;
    min-height: 100px;
    background: transparent;
    border: black solid 5px;
    margin: 15px 25px;
    border-radius: 15px;
    border-color: transparent;
    display: flex;
    padding: 1px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 40px;
      height: 40px;
      fill: white;
      margin: 2px;
      stroke: white;
    }

    .fullname {
      margin: 2px;
      font-size: 13px;
      text-align: center;
      color: white;
      font-weight: 800;
    }

    .number-of-datapoints {
      background: #e55e5e;
      padding: 5px;
      border-radius: 7px;
      color: white;
      position: absolute;
      top: -17px;
      right: -17px;
      box-shadow: 0 0 0 4px #ffffff;
    }

    &--misc {
      min-height: 50px;
      background: $miscColor;
    }

    &--ggr {
      background: $ggrColor;
    }

    &--srm {
      background: $srmColor;
    }
    &--wm {
      background: $wmColor;
    }
    &--other {
      background: $otherColor;
    }
  }
}

@media (max-width: 1000px) {
  .controls-container {
    left: 0;
    bottom: 0;
    height: calc(20vh + 35px);
    height: calc(20svh + 35px);
    max-height: 200px;

    .button--close {
      margin-left: calc(100% - 40px);
    }

    .datefilter {
      position: fixed;
      right: 0;
      top: 0;
      margin: 25px 20px;

      .button--close {
        display: block;
        position: absolute;
        right: -15px;
        top: -15px;
        margin: 0;
        background: white;
        border-radius: 50%;
      }

      &.minimize span,
      &.minimize h4,
      {
        display: none;
      }

      &.minimize .button--close,
      {
        height: 100%;
        border-radius: 5px;
        color: black;
        border: none;
        font-size: 15px;
        font-weight: bold;
        padding: 15px;
      }
    }

    .control-buttons {
      width: 100%;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 15px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border: 5px solid transparent;
      border-bottom: none;
      border-right: 15px solid transparent;

      .control-button {
        height: 75px;
        min-height: 50px;
        min-width: 90px;
        margin: 20px 5px;
        border-color: transparent;

        &:first-child {
          margin-left: 15px;
        }

        svg {
          display: none;
        }

        &--misc {
          min-height: 50px;
          box-shadow: none;
          background: $miscColor;
        }

        &--ggr {
          background: $ggrColor;
          box-shadow: none;
        }

        &--srm {
          background: $srmColor;
          box-shadow: none;
        }
        &--wm {
          background: $wmColor;
          box-shadow: none;
        }
        &--other {
          background: $otherColor;
          box-shadow: none;
        }

        &.control-button--about-map {
          margin-right: 15px;
        }


      }
    }
  }
}
