@import "../scss/variables.scss";
@import "../scss/transitions.scss";

.filter-view-all {
  background: white;
  color: $miscColor;


  .filter--list--ggr {
    background: $ggrColor !important;
    color: white !important;
  }

  .filter--list--srm {
    background: $srmColor !important;
    color: white !important;
  }

  .filter--list--wm {
    background: $wmColor !important;
    color: white !important;
  }

  .filter--list--other {
    background: $otherColor !important;
    color: white !important;
  }



  .button--close {
    color: $miscColor;
  }
  .button--close > svg {
    stroke: $miscColor !important;
  }

  .clear-all-filter-container {
    h4 {
      text-align: center;
      background: $miscColor;
      color: white;
      padding: 15px;
      border-radius: 15px;
      margin: 25px;
    }
    .filter--list {
      margin: 25px;
    }
  }
}
